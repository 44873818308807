let deleteRowItem = {
  methods: {
    // 删除 && 删除后的回调
    successDelete() {
      this.$message.success('删除成功')
      this.getList()
    },
    showDelete(scope) {
      this.timestamp = parseInt(new Date().getTime())
      sessionStorage.setItem('deleteItemId', scope.row.id)
    }
  }
}

export { deleteRowItem }
