<route>
{
  "meta": {
    "auth": "tanchiLeaderboard"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20">
        <el-col :span="6">
          <span class="searchText">商家ID</span>
          <el-input class="searchInput" v-model.trim="searchValue.businessId" placeholder="请输入商家ID" clearable>
          </el-input>
        </el-col>
        <el-col :span="6">
          <span class="searchText">商家名称</span>
          <el-input class="searchInput" v-model.trim="searchValue.businessTitle" placeholder="请输入商家名称" clearable>
          </el-input>
        </el-col>

        <el-col :span="6">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>贪吃榜列表</span>
        <el-button @click="edit('')" type="success" style="float: right; ">添加</el-button>
      </div>

      <div style="margin: 14px 7px" v-if="$store.getters['user/isAllArea']">
        <span class="text">贪吃榜对应城市</span>
        <el-select class="city" placeholder="请选择城市" @change="handleCityChange" v-model="form.areaId">
          <el-option v-if="form.areaId == -1" label="全国" :value="-1"></el-option>
          <el-option
            v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column label="排序" sortable align="center" prop="sortNo"> </el-table-column>

        <el-table-column label="商家ID" align="center" prop="businessId"> </el-table-column>
        <el-table-column prop="businessTitle" align="center" label="商家名称"> </el-table-column>
        <el-table-column align="center" prop="areaId" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaId | formatArea }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="showDelete(scope)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="Dialog" width="30%">
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="搜索商家">
          <el-input :disabled="isEdit" v-model.trim="searchInput" class="input-with-select">
            <el-select class="selectType" v-model="selectSearch" slot="prepend">
              <el-option label="ID" value="1"></el-option>
              <el-option label="名称" value="2"></el-option>
            </el-select>
            <el-button slot="append" @click="handleSearchVendor" icon="el-icon-search"></el-button> </el-input
        ></el-form-item>
        <el-form-item label="选择商家" prop="businessId">
          <el-select
            :disabled="isEdit"
            class="selectVendor"
            v-model="selectVendor"
            no-data-text="没有搜到商家"
            @change="handleVendorChange"
          >
            <el-option
              v-for="item in vendorList"
              :label="item.businessTitle"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sortNo">
          <el-input-number v-model="form.sortNo" controls-position="right" :min="0" :max="99"></el-input-number
        ></el-form-item>

        <el-form-item label="推荐理由" prop="recommendContent">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            placeholder="请输入推荐理由"
            v-model.trim="form.recommendContent"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="推荐图" prop="recommendImage">
          <bm-upload type="system" :imageList="[form.recommendImage]" @success="res => (form.recommendImage = res[0])">
          </bm-upload>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="Dialog = false">取消</el-button>
        <el-button round type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/businessEsurient/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      rules: {},
      vendorList: [],
      searchValue: {
        businessTitle: '',
        businessId: ''
      },
      selectVendor: '',
      cityName: '',
      searchInput: '',
      selectSearch: '2',
      title: '',
      form: {
        areaId: -1,
        businessId: '',
        businessTitle: '',
        recommendContent: '',
        recommendImage: '',
        sortNo: 0
      },
      timestamp: 0,
      list: [],
      isEdit: false,
      Dialog: false
    }
  },
  methods: {
    handleVendorChange() {
      console.log(this.selectVendor)

      this.vendorList.forEach(item => {
        if (this.selectVendor === item.id) {
          this.form.businessTitle = item.businessTitle
          this.form.businessId = item.id
        }
      })
    },
    handleSearchVendor() {
      if (this.searchInput === '') {
        this.$message.error(`请输入要搜索的商家${this.selectSearch === '1' ? 'ID' : '名称'}`)
        return
      }
      this.selectVendor = ''
      let params = {
        params: {
          page: 1,
          size: 100,
          businessId: this.selectSearch === '1' ? this.searchInput : '',
          searchByName: this.selectSearch === '2' ? this.searchInput : '',
          areaId: this.form.areaId
        },
        headers:
          sessionStorage.currentAreaId != -1
            ? {}
            : {
                ['Bm-Area-Id']: this.form.areaId
              }
      }

      this.$api.vendor
        .vendor('businessList', params)
        .then(res => {
          this.vendorList = res.list
          document.getElementsByClassName('selectVendor')[0].click()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    getRules() {
      this.rules = this.$initRules([
        {
          label: '选择商家',
          value: 'businessId',
          type: 'select',
          required: true
        },
        {
          label: '推荐理由',
          value: 'recommendContent',
          type: 'input',
          required: true
        },
        {
          label: '排序',
          value: 'sortNo',
          type: 'input',
          required: true
        }
      ])
    },
    handleCityChange() {
      this.$store.getters['user/userAreaInfoListWithoutGlobal'].forEach(item => {
        if (this.form.areaId === item.id) {
          this.cityName = item.name
        }
      })
      this.getList()
    },
    edit(row) {
      this.vendorList = []
      this.searchInput = ''
      this.Dialog = true
      if (row) {
        this.title = '编辑贪吃榜 — ' + this.cityName
        this.form.businessId = row.businessId
        this.form.businessTitle = row.businessTitle
        this.form.recommendContent = row.recommendContent
        this.form.recommendImage = row.recommendImage
        this.form.sortNo = row.sortNo
        this.form.id = row.id
        this.isEdit = true
        this.selectVendor = row.businessTitle
      } else {
        this.title = '新增贪吃榜 — ' + this.cityName
        this.isEdit = false

        this.selectVendor = ''
        this.form = {
          areaId: this.form.areaId,
          businessId: '',
          businessTitle: '',
          recommendContent: '',
          recommendImage: '',
          sortNo: 0
        }
      }
    },
    confirm() {
      let params = {
        recommendContent: this.form.recommendContent,
        recommendImage: this.form.recommendImage,
        sortNo: this.form.sortNo
      }
      let config =
        sessionStorage.currentAreaId != -1
          ? {}
          : {
              headers: {
                ['Bm-Area-Id']: this.form.areaId
              }
            }
      this.$refs['form'].validate((valid, validObj) => {
        if (valid) {
          let { areaId, ...payload } = this.form
          if (this.isEdit) {
            params.id = this.form.id

            this.$api.mall
              .mall('putTanchi', params, config)
              .then(res => {
                this.$message.success('修改成功')
                this.Dialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          } else {
            this.$api.mall
              .mall('postTanchi', payload, config)
              .then(res => {
                this.$message.success('添加成功')
                this.Dialog = false
                this.getList()
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
          }
        } else {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
        }
      })
    },
    // 重置搜索
    resetSearch() {
      this.searchValue = {
        activityName: '',
        areaId: ''
      }
      this.getList()
    },
    // 搜索贪吃榜
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      let areaId
      areaId = this.form.areaId === -1 ? undefined : this.form.areaId
      if (areaId == undefined && sessionStorage.currentAreaId == -1) {
        return
      }
      let params = {
        params: {
          businessId: this.searchValue.businessId,
          businessTitle: this.searchValue.businessTitle,
          page: this.currentPage,
          size: this.pageSize
        },
        headers: {
          ['Bm-Area-Id']: areaId
        }
      }
      this.$api.mall
        .mall('getTanchiList', params)
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getRules()
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.form.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
      this.cityName = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].name
    }

    this.getList()
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input-group__prepend .el-input {
  font-size: inherit;
  width: 75px;
}
.el-dialog {
  .input-with-select,
  .el-select,
  .el-input-number,
  .el-textarea,
  .selectVendor,
  .el-input {
    width: 250px;
  }
  .selectType {
    width: 70px;
  }
}
.tableContainer {
  .text {
    margin-right: 10px;
  }
  .city {
    width: 100px;
  }
}

.query {
  margin-top: 20px;
}
</style>
